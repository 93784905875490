import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Content, { HTMLContent } from "../components/Content";
import Arrow from "../icons/arrow";
// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="w-full max-w-screen-xl px-6 mx-auto content">
        <div className="max-w-5xl pt-8 pb-12 columns md:py-16">
          <Link to={`/blog`} className="flex items-center">
            <div className={`rotate-180 mr-2`}>
              <Arrow color="#404040" width="w-6" />
            </div>
            Blog
          </Link>
          <div className="column is-10 is-offset-1">
            <div className={`md:py-10`}>
              <h1 className="lg:pr-12">{title}</h1>
            </div>
            <p className={"prose prose-lg lg:prose-xl mb-8"}>{description}</p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }} className="flex items-center">
                <span className="pr-6">Tags</span>
                <ul className="flex taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`} className="mr-4">
                      <Link
                        to={`/tags/${kebabCase(tag)}/`}
                        className="block border transition px-4 outline-none hover:bg-transparent hover:text-[#448FDE] border-[#448FDE] bg-[#448FDE] text-white rounded-xl"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
